var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.totalPages > 1 ? _c('div', {
    key: _vm.total,
    staticClass: "numbers-wrapper"
  }, [_c('ul', {
    staticClass: "pagination"
  }, [_c('li', {
    staticClass: "pagination-item",
    staticStyle: {
      "height": "40px",
      "width": "40px",
      "margin-right": "20px"
    }
  }, [_c('button', {
    staticClass: "button-arrows",
    attrs: {
      "type": "button",
      "disabled": _vm.isInFirstPage
    },
    on: {
      "click": _vm.onClickPreviousPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/arrow-pagination-left.svg"),
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "pagination-item"
  }, [_c('button', {
    class: {
      active: _vm.currentPage === 1,
      'button-numbers': _vm.currentPage !== 1
    },
    attrs: {
      "type": "button",
      "disabled": _vm.isInFirstPage
    },
    on: {
      "click": _vm.firstPage
    }
  }, [_vm._v(" 1 ")])]), _vm.pages[0] !== 2 && _vm.pages[0] !== 1 ? _c('li', {
    staticClass: "pagination-item"
  }, [_vm._v(" ... ")]) : _vm._e(), _vm._l(_vm.pages, function (page, index) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: page !== 1 && page !== _vm.totalPages,
        expression: "page !== 1 && page !== totalPages"
      }],
      key: index,
      staticClass: "pagination-item"
    }, [_c('button', {
      class: {
        active: _vm.isPageActive(page),
        'button-numbers': !_vm.isPageActive(page)
      },
      attrs: {
        "type": "button",
        "disabled": page.isDisabled
      },
      on: {
        "click": function ($event) {
          return _vm.onClickPage(page);
        }
      }
    }, [_vm._v(" " + _vm._s(page) + " ")])]);
  }), _vm.pages[_vm.pages.length - 1] !== _vm.totalPages - 1 ? _c('li', {
    staticClass: "pagination-item"
  }, [_vm._v(" ... ")]) : _vm._e(), _c('li', {
    staticClass: "pagination-item"
  }, [_c('button', {
    class: {
      active: _vm.currentPage === _vm.totalPages,
      'button-numbers': _vm.currentPage !== _vm.totalPages
    },
    attrs: {
      "type": "button",
      "disabled": _vm.isInLastPage
    },
    on: {
      "click": _vm.lastPage
    }
  }, [_vm._v(" " + _vm._s(_vm.totalPages) + " ")])]), _c('li', {
    staticClass: "pagination-item",
    staticStyle: {
      "height": "40px",
      "width": "40px",
      "margin-left": "20px"
    }
  }, [_c('button', {
    staticClass: "button-arrows",
    attrs: {
      "type": "button",
      "disabled": _vm.isInLastPage
    },
    on: {
      "click": _vm.onClickNextPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/arrow-pagination-right.svg"),
      "alt": ""
    }
  })])])], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }