<template>
  <div :key="total" class="numbers-wrapper" v-if="totalPages > 1">
    <ul class="pagination">
      <li
        class="pagination-item"
        style="height: 40px; width: 40px; margin-right: 20px"
      >
        <button
          class="button-arrows"
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        >
          <img src="../../assets/images/arrow-pagination-left.svg" alt="" />
        </button>
      </li>
      <li
          class="pagination-item"
      >
        <button
            type="button"
            @click="firstPage"
            :disabled="isInFirstPage"
            v-bind:class="{
            active: currentPage === 1,
            'button-numbers': currentPage !== 1,
          }"
        >
          1
        </button>
      </li>
      <li
          class="pagination-item"
          v-if="pages[0] !== 2 && pages[0] !== 1"
      >
        ...
      </li>
      <li
        v-for="(page, index) in pages"
        :key="index"
        v-show="page !== 1 && page !== totalPages"
        class="pagination-item"
      >
        <button
          type="button"

          @click="onClickPage(page)"
          :disabled="page.isDisabled"
          v-bind:class="{
            active: isPageActive(page),
            'button-numbers': !isPageActive(page),
          }"
        >
          {{ page }}
        </button>
      </li>
      <li
          class="pagination-item"
          v-if="pages[pages.length - 1] !== totalPages - 1"
      >
        ...
      </li>
      <li
          class="pagination-item"
      >
        <button
            type="button"
            @click="lastPage"
            :disabled="isInLastPage"
            v-bind:class="{
            active: currentPage === totalPages,
            'button-numbers': currentPage !== totalPages,
          }"
        >
          {{ totalPages }}
        </button>
      </li>

      <li
        class="pagination-item"
        style="height: 40px; width: 40px; margin-left: 20px"
      >
        <button
          class="button-arrows"
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
        >
          <img src="../../assets/images/arrow-pagination-right.svg" alt="" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaginationNumbers",
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      required: true,
    },
  },
  data() {
    return {
      numbersToShow: 20,
      curPage: this.currentPage,
    };
  },
  created() {
    if (this.$route.query.page > this.totalPages) {
      this.lastPage()
    }
  },
  computed: {
    pages() {
      let total = parseInt(this.totalPages - 1)
      const numbersToShow = Math.min(total, this.numbersToShow);
      let first = this.curPage - Math.floor(numbersToShow / 2);
      first = Math.max(first, 2);
      first = Math.min(first, total - numbersToShow + 1);
      return [...Array(numbersToShow)].map((k, i) => i + first);
    },

    startPage() {
      if (this.curPage === 1) {
        return 1;
      }

      if (this.curPage === this.totalPages) {
        return this.totalPages;
      }

      return this.curPage - 1;
    },
    endPage() {
      return Math.min(this.startPage, this.totalPages);
    },
    isInFirstPage() {
      return this.curPage === 1;
    },
    isInLastPage() {
      return this.curPage === this.totalPages;
    },
  },
  watch: {
    totalPages(val) {
      console.log("total: ", val);
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit("pagechanged", this.curPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.curPage + 1);
    },
    isPageActive: function (page) {
      if(this.$route.query.page) {
        this.curPage = parseInt(this.$route.query.page);
        return this.curPage === page;
      }
    },
    firstPage() {
      this.$emit("pagechanged", 1);
    },
    lastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
  },
};
</script>

<style lang="scss" scoped>
.numbers-wrapper {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-item {
  display: inline-block;
  margin: 4px;
}

.active {
  background-color: #ff5c01;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  color: #ffffff;
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.button-arrows {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-numbers {
  width: 24px;
  height: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}
</style>
